import { Injectable } from '@angular/core';
import { Story } from './../_models/content.model';

@Injectable({
  providedIn: 'root'
})
export class WhatsNewService {
  stories: Array<Story> = [
    {
      id: 1,
      screen: "whats-new",
      title: "MMM Guidelines Regarding Business Resumption",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut ",
      fullDescription: "<img src='assets/carousel-img.jpg' class='img-fluid' alt='MMM Guidelines Regarding Business Resumption'><br><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut</p>",
      date: "October 2020",
      link: "/article/whats-new/1",
      backLink: "whats-new",
      image: ["assets/carousel-img.jpg"],
      isFeatured: true,
    },
    {
      id: 2,
      screen: "whats-new",
      title: "90-day Crew Replacement Plan",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure ",
      fullDescription: "<img src='assets/whats-new/90day-crew-replacement-plan.jpg' class='img-fluid' alt='90-day Crew Replacement Plan'><br><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure</p>",
      date: "September 30, 2020",
      link: "/article/whats-new/2",
      backLink: "whats-new",
      image: ["assets/whats-new/90day-crew-replacement-plan.jpg"],
      isFeatured: false,
    },
    {
      id: 3,
      screen: "whats-new",
      title: "MOL Circular",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure ",
      fullDescription: "<img src='assets/whats-new/mol-circular.jpg class='img-fluid' alt='MOL Circular'><br><br><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure</p>",
      date: "September 30, 2020",
      link: "/article/whats-new/3",
      backLink: "whats-new",
      image: ["assets/whats-new/mol-circular.jpg"],
      isFeatured: false,
    },
  ];
  constructor() { }

  getMainStory() {
    return this.stories.filter(x => x.isFeatured == true)[0];
  }

  getSubStories() {
    return this.stories.filter(x => x.isFeatured == false);
  }

  getStoryId(id: number) {
    return this.stories.filter(x => x.id == id)[0];
  }

  getOtherStories(id: number) {
    var filteredStories = this.stories.filter(x => x.id != id);
    this.shuffleArray(filteredStories);
    return filteredStories.slice(0, 3);
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }
}
