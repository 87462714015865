export class Global {

  constructor() {
    return {
      spaceToUnderscore: function (text: string) {
        return text.replace(/\s/g, '-').toLowerCase().replace(/,/g, "").replace(/!/g, "").replace(/'/g, "");
       }
    }
  }
}
