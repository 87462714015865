import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgxGalleryModule } from 'ngx-gallery';

import * as Hammer from 'hammerjs';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
//import { CounterComponent } from './counter/counter.component';
//import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { SeaspiritComponent } from './seaspirit/seaspirit.component';
import { MainstoryComponent } from './contents/mainstory.component';
import { SubstoryComponent } from './contents/substory.component';
//import { WhatsNewComponent } from './whats-new/whats-new.component';

import { SeaspiritService } from './_services/seaspirit.service';
import { WhatsNewService } from './_services/whats-new.service';
import { ArticlespageComponent } from './contents/articlespage.component';
import { CrewAndFamilyComponent } from './crew-and-family/crew-and-family.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export class CustomHammerConfig extends HammerGestureConfig {
  overrides = {
    'pan': {
      direction: Hammer.DIRECTION_ALL,
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    //CounterComponent,
    //FetchDataComponent,
    AboutComponent,
    ServicesComponent,
    SeaspiritComponent,
    MainstoryComponent,
    SubstoryComponent,
    //WhatsNewComponent,
    ArticlespageComponent,
    CrewAndFamilyComponent,
    ContactUsComponent,
    PageNotFoundComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    NgxGalleryModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      //{ path: 'counter', component: CounterComponent },
      //{ path: 'fetch-data', component: FetchDataComponent },
      { path: 'about-mmm', component: AboutComponent },
      { path: 'our-services', component: ServicesComponent },
      { path: 'seaspirit', component: SeaspiritComponent },
      { path: 'article/:screen/:id/:title', component: ArticlespageComponent },
      { path: 'crew-and-family-affairs', component: CrewAndFamilyComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
    ], { anchorScrolling: 'enabled', scrollPositionRestoration: 'top', scrollOffset: [0, -6] }),
  ],
  providers: [Title, SeaspiritService, WhatsNewService,
  { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig }],
  bootstrap: [AppComponent]
})
export class AppModule { }
