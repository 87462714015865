import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor(
    private titleService: Title,
    private metaTagService: Meta) {

  }

  ngOnInit() {
    this.titleService.setTitle("About MAGMOL | Magsaysay MOL Marine");
    this.metaTagService.addTags([
      { name: 'keywords', content: "About, Magsaysay MOL Marine, MMM" },
      { name: 'description', content: 'Magsaysay MOL Marine, Inc. (MMM) was established in June 1997 aiming at enhancing the long time partnership between Magsaysay Maritime Corporation (MMC)' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Magsaysay MOL Marine' },
    ]);

    this.galleryOptions = [
      {
        width: '100%',
        height: '500px',
        thumbnailsMargin: 10,
        thumbnailMargin: 10,
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageArrows: true,
        imageInfinityMove: true,
        thumbnailsArrows: true,
        previewCloseOnClick: true,
        previewCloseOnEsc: true,
        previewInfinityMove: true,
        closeIcon: 'fa fa-times-circle',
        arrowPrevIcon: 'fa fa-arrow-circle-left',
        arrowNextIcon: 'fa fa-arrow-circle-right'
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        layout: 'NgxGalleryLayout.Top',
      },
      // max-width 400
      {
        breakpoint: 300
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/Slide-medium-1.jpg',
        medium: 'assets/Slide-medium-1.jpg',
        big: 'assets/Slide1.JPG'
      },
      {
        small: 'assets/Slide-medium-2.jpg',
        medium: 'assets/Slide-medium-2.jpg',
        big: 'assets/Slide2.JPG'
      },
      {
        small: 'assets/Slide-medium-3.jpg',
        medium: 'assets/Slide-medium-3.jpg',
        big: 'assets/Slide3.JPG'
      },
      {
        small: 'assets/Slide-medium-4.jpg',
        medium: 'assets/Slide-medium-4.jpg',
        big: 'assets/Slide4.JPG'
      },
      {
        small: 'assets/Slide-medium-5.jpg',
        medium: 'assets/Slide-medium-5.jpg',
        big: 'assets/Slide5.JPG'
      },
      {
        small: 'assets/Slide-medium-6.jpg',
        medium: 'assets/Slide-medium-6.jpg',
        big: 'assets/Slide6.JPG'
      },
      {
        small: 'assets/Slide-medium-7.jpg',
        medium: 'assets/Slide-medium-7.jpg',
        big: 'assets/Slide7.JPG'
      },
      {
        small: 'assets/Slide-medium-8.jpg',
        medium: 'assets/Slide-medium-8.jpg',
        big: 'assets/Slide8.JPG'
      },
      {
        small: 'assets/Slide-medium-9.jpg',
        medium: 'assets/Slide-medium-9.jpg',
        big: 'assets/Slide9.JPG'
      }
    ];
  }

}
