export class Story {
  category?: string;
  id: number;
  screen: string;
  title: string;
  image: string[];
  description: string;
  fullDescription: string;
  date?: string;
  link: string;
  backLink?: string;
  isFeatured?: boolean;
}

