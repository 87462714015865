import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Story } from './../_models/content.model';
import { Global } from '../global';

@Component({
  selector: 'app-substory',
  templateUrl: './substory.component.html',
  styleUrls: ['./substory.component.css']
})
export class SubstoryComponent implements OnInit {
  g: Global = new Global();
  @Input() subStory: Story;

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
