import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../_services/contact.service';
import { Contact } from '../_models/contact.model';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  @ViewChild("successAlert", { static: true }) successAlert: ElementRef;
  @ViewChild("failAlert", { static: true }) failAlert: ElementRef;

  contactForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;

  constructor(private titleService: Title,
    private metaTagService: Meta,
    private formBuilder: FormBuilder,
    private contactService: ContactService
  )
  {
  }
  ngOnInit() {
    this.titleService.setTitle("Contact Us | Magsaysay MOL Marine");
    this.metaTagService.addTags([
      { name: 'keywords', content: "Contact, Magsaysay MOL Marine, MMM" },
      { name: 'description', content: 'Location: 5th & 6th Floor Magsaysay Building 520 T.M. Kalaw Street, Ermita Manila 1000 Philippines, Magsaysay-Mitsui OSK Marine, Inc. Telephone: +63 2 8526 8888 | Fax +63 2 524 8132' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Magsaysay MOL Marine' },
    ]);

    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      details: ['', Validators.required]
    });

    this.successAlert.nativeElement.hidden = true;
    this.failAlert.nativeElement.hidden = true;
  }

  get f() { return this.contactForm.controls; }

  clearFields() {
    this.f.firstName.setValue("");
    this.f.lastName.setValue("");
    this.f.email.setValue("");
    this.f.subject.setValue("");
    this.f.details.setValue("");
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;

    //console.log("this.contactForm", this.contactForm);
    if (this.contactForm.invalid) {
      this.loading = false;
      return;
    }

    var form = this.contactForm.value;

    var contact = new Contact();
    contact.fullName = form.firstName + " " + form.lastName;
    contact.email = form.email;
    contact.subject = form.subject;
    contact.details = form.details;

    this.contactService.postContact(contact)
      .subscribe(
        (res) => {
          this.submitted = false;
          this.loading = false;
          this.successAlert.nativeElement.hidden = false;
          setTimeout(() => {
            this.successAlert.nativeElement.hidden = true;
            this.clearFields();
          }, 5000);

        },
        (error) => {
          this.submitted = false;
          this.loading = false;
          this.failAlert.nativeElement.hidden = false;
        }
      );
  }
}
