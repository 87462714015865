import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Story } from './../_models/content.model'
import { SeaspiritService } from './../_services/seaspirit.service';
import { Global } from '../global';

@Component({
  selector: 'app-seaspirit',
  templateUrl: './seaspirit.component.html',
  styleUrls: ['./seaspirit.component.css']
})
export class SeaspiritComponent implements OnInit {
  g: Global = new Global();

  mainStory: Story;
  subStories: Story[] = [];

  constructor(private titleService: Title, private metaTagService: Meta,
    private seaSpiritService: SeaspiritService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location) {

    this.mainStory = this.seaSpiritService.getMainStory();
    this.subStories = this.seaSpiritService.getSubStories().slice(0, 3);    
  }

  ngOnInit() {
    this.titleService.setTitle("Seaspirit | Magsaysay MOL Marine");
    this.metaTagService.addTags([
      { name: 'keywords', content: "Seaspirit, Magsaysay MOL Marine" },
      { name: 'description', content: 'Seaspirit Press Release: MOL Presents Long-Service Awards, Hosts Family Day for Filipino Crewmembers' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Magsaysay MOL Marine' },
    ]);
  }

}
