import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor(private titleService: Title,
    private metaTagService: Meta) {

  }

  ngOnInit() {
    this.titleService.setTitle("Our Services | Magsaysay MOL Marine");
    this.metaTagService.addTags([
      { name: 'keywords', content: "Services, Magsaysay MOL Marine" },
      { name: 'description', content: 'We are looking for candidates who can adjust to the rapidly changing circumstances in the maritime world!​​ MMM pools its future maritime leaders from MOL Magsaysay Maritime Academy Inc. (MMMA) in Dasmariñas City, Cavite State.​​​' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Magsaysay MOL Marine' },
    ]);
  }

}
