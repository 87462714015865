import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Story } from './../_models/content.model';
import { Global } from '../global';

@Component({
  selector: 'app-mainstory',
  templateUrl: './mainstory.component.html',
  styleUrls: ['./mainstory.component.css']
})
export class MainstoryComponent implements OnInit {
  g: Global = new Global();
  @Input() mainStory: Story;

  constructor(private router: Router) { }

  ngOnInit() {
  }

}
