import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { Story } from './../_models/content.model';
import { WhatsNewService } from './../_services/whats-new.service';
import { SeaspiritService } from './../_services/seaspirit.service';
import { CrewAndFamilyService } from './../_services/crew-and-family.service';
import { Global } from '../global';

@Component({
  selector: 'app-articlespage',
  templateUrl: './articlespage.component.html',
  styleUrls: ['./articlespage.component.css']
})
export class ArticlespageComponent implements OnInit {

  g: Global = new Global();

  story: Story;
  subStories: Story[] = [];
  screen: string;
  groupStory: any;
  otherStories: Story[] = [];

  private routeSub: Subscription;

  constructor(private titleService: Title, private metaTagService: Meta,
    private seaSpiritService: SeaspiritService,
    private whatsNewService: WhatsNewService,
    private crewAndFamilyService: CrewAndFamilyService,
    private route: ActivatedRoute,
    private router: Router) {

    this.routeSub = this.route.params.subscribe(params => {
      var storyId = params['id'];
      this.screen = params['screen'];
      this.screen = this.screen.toLowerCase();

      if(this.screen == "seaspirit") {
        this.story = this.seaSpiritService.getStoryId(storyId);
        this.otherStories = this.seaSpiritService.getOtherStories(storyId).slice(0, 3);
      }
      else if (this.screen == "whats-new") {
        this.story = this.whatsNewService.getStoryId(storyId);
        this.otherStories = this.whatsNewService.getOtherStories(storyId).slice(0, 3);
      }
      else if (this.screen == "crew-and-family-affairs") {
        this.story = this.crewAndFamilyService.getStoryId(storyId);
        this.otherStories = this.crewAndFamilyService.getOtherStories(storyId).slice(0, 3);
      }
    });

    this.metaTagService.addTags([
      { name: 'keywords', content: "Article, Magsaysay MOL Marine" },
      { name: 'description', content: 'Article' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Magsaysay MOL Marine' },
    ]);

    this.titleService.setTitle(this.story.title + " | " + "Magsaysay World");
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe((event) => {
        this.titleService.setTitle(this.story.title + " | " + "Magsaysay World");
      });
  }

  ngOnInit() {
    
  }

}
