import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, formatDate } from '@angular/common';
import { Story } from './../_models/content.model'
import { WhatsNewService } from './../_services/whats-new.service';
import { Global } from '../global';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  galleryOptions: NgxGalleryOptions[];
  galleryDRY: NgxGalleryImage[];
  galleryLNG: NgxGalleryImage[];
  gallerySEA: NgxGalleryImage[];
  galleryLAND: NgxGalleryImage[];

  g: Global = new Global();
  showSurvery = true;

  mainStory: Story;
  constructor(private titleService: Title, private metaTagService: Meta,
    private whatsNewService: WhatsNewService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location)
  {
    this.mainStory = this.whatsNewService.getMainStory();
    var dateToday = new Date();
    var endDate = new Date('2021-03-01');
    if (dateToday >= endDate)
      this.showSurvery = false;
  }

  ngOnInit() {
    this.titleService.setTitle("Home | Magsaysay MOL Marine");
    this.metaTagService.addTags([
      { name: 'keywords', content: "Home, MOL" },
      { name: 'description', content: 'To Believe That Ultimate Goal is attainable' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Magsaysay MOL Marine' },
    ]);

    //NgxGalleryImage
    this.galleryOptions = [
      {
        width: '100%',
        height: '520px',
        thumbnailsMargin: 10,
        thumbnailMargin: 10,
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        imageArrows: true,
        imageInfinityMove: true,
        thumbnailsArrows: true,
        previewCloseOnClick: true,
        previewCloseOnEsc: true,
        previewInfinityMove: true,
        closeIcon: 'fa fa-times-circle',
        arrowPrevIcon: 'fa fa-arrow-circle-left',
        arrowNextIcon: 'fa fa-arrow-circle-right'
      },
      // max-width 800
      {
        breakpoint: 900,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20,
        layout: 'NgxGalleryLayout.Top',
      },
      // max-width 400
      {
        breakpoint: 767,
        height: '305px'
      }
    ];

    //this.galleryDRY = [
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide1.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide1.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide1.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide2.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide2.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide2.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide3.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide3.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide3.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide4.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide4.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide4.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide5.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide5.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide5.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide6.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide6.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide6.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide7.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide7.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide7.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide8.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide8.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide8.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/DRY/Slide9.JPG',
    //    medium: 'assets/JOB POSTING/DRY/Slide9.JPG',
    //    big: 'assets/JOB POSTING/DRY/Slide1.JPG'
    //  },
      //{
      //  small: 'assets/DRY/DRY_able-seaman.JPG',
      //  medium: 'assets/DRY/DRY_able-seaman.JPG',
      //  big: 'assets/DRY/DRY_able-seaman.JPG'
      //},
      //{
      //  small: 'assets/DRY/DRY_chef-cook.JPG',
      //  medium: 'assets/DRY/DRY_chef-cook.JPG',
      //  big: 'assets/DRY/DRY_chef-cook.JPG'
      //},
      //{
      //  small: 'assets/DRY/DRY_eto-etr.JPG',
      //  medium: 'assets/DRY/DRY_eto-etr.JPG',
      //  big: 'assets/DRY/DRY_eto-etr.JPG'
      //},
      //{
      //  small: 'assets/DRY/DRY_messman.JPG',
      //  medium: 'assets/DRY/DRY_messman.JPG',
      //  big: 'assets/DRY/DRY_messman.JPG'
      //},
      //{
      //  small: 'assets/DRY/DRY_oiler.JPG',
      //  medium: 'assets/DRY/DRY_oiler.JPG',
      //  big: 'assets/DRY/DRY_oiler.JPG'
      //},
      //{
      //  small: 'assets/DRY/DRY_ordinary-seaman.JPG',
      //  medium: 'assets/DRY/DRY_ordinary-seaman.JPG',
      //  big: 'assets/DRY/DRY_ordinary-seaman.JPG'
      //},
      //{
      //  small: 'assets/DRY/DRY_wiper.JPG',
      //  medium: 'assets/DRY/DRY_wiper.JPG',
      //  big: 'assets/DRY/DRY_wiper.JPG'
      //},
    //];

    //this.galleryLNG = [
    //  {
    //    small: 'assets/JOB POSTING/LNG/Slide1.JPG',
    //    medium: 'assets/JOB POSTING/LNG/Slide1.JPG',
    //    big: 'assets/JOB POSTING/LNG/Slide1.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/LNG/Slide2.JPG',
    //    medium: 'assets/JOB POSTING/LNG/Slide2.JPG',
    //    big: 'assets/JOB POSTING/LNG/Slide2.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/LNG/Slide3.JPG',
    //    medium: 'assets/JOB POSTING/LNG/Slide3.JPG',
    //    big: 'assets/JOB POSTING/LNG/Slide3.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/LNG/Slide4.JPG',
    //    medium: 'assets/JOB POSTING/LNG/Slide4.JPG',
    //    big: 'assets/JOB POSTING/LNG/Slide4.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/LNG/Slide5.JPG',
    //    medium: 'assets/JOB POSTING/LNG/Slide5.JPG',
    //    big: 'assets/JOB POSTING/LNG/Slide5.JPG'
    //  },
    //  {
    //    small: 'assets/JOB POSTING/LNG/Slide6.JPG',
    //    medium: 'assets/JOB POSTING/LNG/Slide6.JPG',
    //    big: 'assets/JOB POSTING/LNG/Slide6.JPG'
    //  },


      //{
      //  small: 'assets/LNG/LNG_2nd-cook.JPG',
      //  medium: 'assets/LNG/LNG_2nd-cook.JPG',
      //  big: 'assets/LNG/LNG_2nd-cook.JPG'
      //},
      //{
      //  small: 'assets/LNG/LNG_able-seman.JPG',
      //  medium: 'assets/LNG/LNG_able-seman.JPG',
      //  big: 'assets/LNG/LNG_able-seman.JPG'
      //},
      //{
      //  small: 'assets/LNG/LNG_messman.JPG',
      //  medium: 'assets/LNG/LNG_messman.JPG',
      //  big: 'assets/LNG/LNG_messman.JPG'
      //},
      //{
      //  small: 'assets/LNG/LNG_oiler.JPG',
      //  medium: 'assets/LNG/LNG_oiler.JPG',
      //  big: 'assets/LNG/LNG_oiler.JPG'
      //},
      //{
      //  small: 'assets/LNG/LNG_ordinary-seaman.JPG',
      //  medium: 'assets/LNG/LNG_ordinary-seaman.JPG',
      //  big: 'assets/LNG/LNG_ordinary-seaman.JPG'
      //},
      //{
      //  small: 'assets/LNG/LNG_wiper.JPG',
      //  medium: 'assets/LNG/LNG_wiper.JPG',
      //  big: 'assets/LNG/LNG_wiper.JPG'
      //}
    //];

    this.gallerySEA = [
      {
        small: 'assets/MMM Job Posting.png',
        medium: 'assets/MMM Job Posting.png',
        big: 'assets/MMM Job Posting.png'
      },
      {
        small: 'assets/JOB POSTING/LNG/Slide1.JPG',
        medium: 'assets/JOB POSTING/LNG/Slide1.JPG',
        big: 'assets/JOB POSTING/LNG/Slide1.JPG'
      },
      {
        small: 'assets/JOB POSTING/LNG/Slide2.JPG',
        medium: 'assets/JOB POSTING/LNG/Slide2.JPG',
        big: 'assets/JOB POSTING/LNG/Slide2.JPG'
      },
      {
        small: 'assets/JOB POSTING/LNG/Slide3.JPG',
        medium: 'assets/JOB POSTING/LNG/Slide3.JPG',
        big: 'assets/JOB POSTING/LNG/Slide3.JPG'
      },
      {
        small: 'assets/JOB POSTING/LNG/Slide4.JPG',
        medium: 'assets/JOB POSTING/LNG/Slide4.JPG',
        big: 'assets/JOB POSTING/LNG/Slide4.JPG'
      },
      {
        small: 'assets/JOB POSTING/LNG/Slide5.JPG',
        medium: 'assets/JOB POSTING/LNG/Slide5.JPG',
        big: 'assets/JOB POSTING/LNG/Slide5.JPG'
      },
      {
        small: 'assets/JOB POSTING/LNG/Slide6.JPG',
        medium: 'assets/JOB POSTING/LNG/Slide6.JPG',
        big: 'assets/JOB POSTING/LNG/Slide6.JPG'
      },
      {
        small: 'assets/TANKER/TANKER_ordinary-seaman.JPG',
        medium: 'assets/TANKER/TANKER_ordinary-seaman.JPG',
        big: 'assets/TANKER/TANKER_ordinary-seaman.JPG'
      },
      {
        small: 'assets/TANKER/TANKER_messman.jpg',
        medium: 'assets/TANKER/TANKER_messman.jpg',
        big: 'assets/TANKER/TANKER_messman.jpg'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide1.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide1.JPG',
        big: 'assets/JOB POSTING/DRY/Slide1.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide2.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide2.JPG',
        big: 'assets/JOB POSTING/DRY/Slide2.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide3.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide3.JPG',
        big: 'assets/JOB POSTING/DRY/Slide3.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide4.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide4.JPG',
        big: 'assets/JOB POSTING/DRY/Slide4.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide5.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide5.JPG',
        big: 'assets/JOB POSTING/DRY/Slide5.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide6.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide6.JPG',
        big: 'assets/JOB POSTING/DRY/Slide6.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide7.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide7.JPG',
        big: 'assets/JOB POSTING/DRY/Slide7.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide8.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide8.JPG',
        big: 'assets/JOB POSTING/DRY/Slide8.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide9.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide9.JPG',
        big: 'assets/JOB POSTING/DRY/Slide9.JPG'
      },
      {
        small: 'assets/JOB POSTING/DRY/Slide10.JPG',
        medium: 'assets/JOB POSTING/DRY/Slide10.JPG',
        big: 'assets/JOB POSTING/DRY/Slide10.JPG'
      }
    ];

    this.galleryLAND = [
      {
        small: 'assets/LAND/MMM-Job-Posting-landbased.jpg',
        medium: 'assets/LAND/MMM-Job-Posting-landbased.jpg',
        big: 'assets/LAND/MMM-Job-Posting-landbased.jpg'
      },
    ];
  }
}

