import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Story } from './../_models/content.model'
import { CrewAndFamilyService } from './../_services/crew-and-family.service';
import { Global } from '../global';

@Component({
  selector: 'app-crew-and-family',
  templateUrl: './crew-and-family.component.html',
  styleUrls: ['./crew-and-family.component.css']
})
export class CrewAndFamilyComponent implements OnInit {
  g: Global = new Global();

  mainStory: Story;
  subStories: any;
  groupStory: any;

  constructor(private titleService: Title, private metaTagService: Meta,
    private crewAndFamilyService: CrewAndFamilyService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location) {

    this.mainStory = this.crewAndFamilyService.getMainStory();
    this.subStories = this.crewAndFamilyService.getSubStories();
  }

  ngOnInit() {
    this.titleService.setTitle("Crew and Family Affairs | Magsaysay MOL Marine");
    this.metaTagService.addTags([
      { name: 'keywords', content: "Crew and Family Affairs, Magsaysay MOL Marine" },
      { name: 'description', content: 'Crew and Family Affairs. For more information on the crew and family programs of MMM, you may click on the Crew Self Service and Crew & Family Services section of www.home-harbor.com​​' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Magsaysay MOL Marine' },
    ]);

    this.groupStory = this.subStories.reduce((r, { category }) => {
      if (!r.some(o => o.category == category)) {
        r.push({ category, groupItem: this.subStories.filter(v => v.category == category).slice(0, 1) });
      }
      return r;
    }, []);
  }

}
